<template>
  <v-container
    fill-height
    fluid
    grid-list-xl>
    <v-layout
      justify-center
      wrap>
      <v-flex md12>
        <form>
          <!-- General Info -->
          <material-card
            :title="$t(productDetail.id !== 0 ? 'product.productDetail' : 'product.addNewProduct')"
            color="green"
            flat
            full-width
            class="bg-white"
          >
            <!-- Box Body -->
            <v-form>
              <v-container py-0>
                <v-layout wrap>
                  <v-flex
                    xs12
                    md6>
                    <v-text-field
                      :rules="[requiredValue]"
                      v-model="productDetail.name"
                      :label="$t('product.name')"
                      class="purple-input required"
                    />
                  </v-flex>
                  <!-- Last Name -->
                  <v-flex
                    xs12
                    md6>
                    <v-text-field
                      :rules="[requiredValue]"
                      v-model="productDetail.productPoint"
                      :label="$t('product.productPoint')"
                      class="purple-input required"
                      type="number"
                    />
                  </v-flex>
                  <!--  -->
                  <v-flex
                    xs12
                    md6>
                    <v-textarea
                      v-model="productDetail.description"
                      :label="$t('product.description')"
                      :name="$t('product.description')"
                      type="text"
                    />
                  </v-flex>
                  <!-- product img -->
                  <v-flex
                    class="text-center"
                    xs12
                    md3
                    for="upload-product-img"
                  >
                    <div>
                      <img
                        id="product-img-id"
                        :src="productDetail.productImg !== null && productDetail.productImg !== '' ? productDetail.productImg : noImg"
                        class="product-img-class img-responsive"
                        alt="product-img">
                    </div>
                    <div class="text-left pt-3">
                      <input
                        id="upload-product-img"
                        type="file"
                        accept="image/*"
                        @change="onSelectProductImg">
                      <p>{{ $t('product.productImgNote') }}</p>
                      <span
                        v-if="productDetail.productImg !== null && productDetail.productImg !== ''"
                        class="remove-product-img"
                        @click="onClickRemoveProductImg">{{ $t('product.removeProductImg') }}</span>
                    </div>
                  </v-flex>
                  <!--  -->
                  <!--  -->
                  <v-flex
                    v-if="productDetail.id != 0 && !isNull(productDetail.id)"
                    class="text-center"
                    xs12
                    md3
                  >
                    <qrcode-vue
                      :value="productCode"
                      :size="150"
                      level="L"
                      render-as="svg"
                    />
                    <!-- <v-chip-group
                      active-class="deep-purple--text text--accent-4"
                      mandatory
                    > -->
                    <v-flex
                      pt-0
                      pb-1
                    >
                      <v-chip class="font-weight-bold">
                        {{ productCode }}
                      </v-chip>
                    </v-flex>
                    <v-chip
                      v-clipboard:copy="getQrUrl(productCode)"
                    >
                      {{ $t('common.copyUrl') }}
                    </v-chip>
                    <!-- </v-chip-group> -->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <!-- /.Box Body -->
            <!-- Box Footer -->
            <v-flex
              xs12
              md12>
              <v-btn
                class="white--text"
                color="error"
                style="margin-right: 5px"
                @click="onClose()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-remove"
                    style="margin-right: 5px"
                  />
                </span>
                {{ $t("common.close") }}
              </v-btn>
              <v-btn
                v-if="isAdmin() || isSuperAdmin() || isModerator() || isAccountant()"
                class="ma-2"
                color="success"
                style="margin-right: 5px"
                @click="saveProduct()"
              >
                <span class="btn-label">
                  <i
                    class="glyphicon glyphicon-ok"
                    style="margin-right: 5px" />
                </span>
                {{ $t("common.save") }}
              </v-btn>
            </v-flex>
          </material-card>
        </form>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
  </v-container>
</template>
<script>
import functionUtils from 'utils/functionUtils'
// import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import { mapActions, mapGetters } from 'vuex'
import QrcodeVue from 'qrcode.vue'
import stringUtils from 'utils/stringUtils'
import RoleType from 'enum/roleType'
import $ from 'jquery'
import axios from 'axios'

export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    menu: false,
    productDetail: {
      id: 0,
      productPoint: null,
      name: null,
      description: null,
      productImg: null
    },
    productCode: '',
    noImg: require('../../assets/noimage.png'),
    productImgFileSize: null,
    media_server: {
      domain: '',
      token: ''
    }
  }),
  computed: {
    ...mapGetters(['GET_PRODUCT_DETAIL_DATA', 'GET_SNACK_BAR', 'GET_TOKEN_MEDIA_SERVER_DATA', 'GET_CURRENT_ACCOUNT'])
  },
  watch: {
    GET_PRODUCT_DETAIL_DATA () {
      let data = this.GET_PRODUCT_DETAIL_DATA
      this.productDetail.name = data.name
      this.productDetail.productPoint = data.points
      this.productDetail.description = data.description
      this.productCode = data.code
      this.productDetail.productImg = data.image_url
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function () {
      this.media_server.token = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token
      this.media_server.domain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain
    }
  },
  created () {
    let productId = this.$route.params.id
    this.productDetail.id = productId
    if (!functionUtils.isNull(productId) && productId !== 0) {
      this.GET_PRODUCT_DETAIL({ id: productId })
    }
    this.GET_TOKEN_MEDIA_SERVER()
  },
  mounted () {
    $(function () {
      $('#product-img-id').on('click', function () {
        $('#upload-product-img').click()
      })
    })
  },
  methods: {
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    // Check is admin
    isAdmin: function () {
      return functionUtils.hasSystemRole(RoleType.ADMIN, this.GET_CURRENT_ACCOUNT)
    },
    isModerator: function () {
      return functionUtils.hasSystemRole(RoleType.MODERATOR, this.GET_CURRENT_ACCOUNT)
    },
    // Check Accountant
    isAccountant: function () {
      return functionUtils.hasSystemRole(RoleType.ACCOUNTANT, this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Required value
     */
    requiredValue (value) {
      if (value !== null && value !== undefined && value.length !== 0) {
        return true
      }
      return this.$t('common.requiredFields')
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    /**
     * validate product
     */
    validateProduct: function () {
      let validateResults = 'OK'
      for (let key in this.productDetail) {
        if (key === 'description' ||
          key === 'productImg') {
          //
        } else if (functionUtils.validateStringIsNull(this.productDetail[key])) {
          validateResults = this.$t('product.' + key)
          break
        }
      }
      return validateResults
    },
    /**
     * Save product
     */
    saveProduct: async function () {
      let results = this.validateProduct()
      if (results === 'OK') {
        let filter = {
          name: this.productDetail.name,
          points: this.productDetail.productPoint,
          description: this.productDetail.description,
          product_img: this.productDetail.productImg
        }
        if (this.productImgFileSize !== null && this.productImgFileSize !== '') {
          await this.uploadProductImg()
          filter['product_img'] = this.productDetail.productImg
        }
        if (this.productDetail.id === 0) {
          await this.CREATE_PRODUCT({ id: this.productDetail.id, ...filter })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.onClose()
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styletype: ToastType.ERROR
                })
              }.bind(this)
            )
        } else {
          await this.UPDATE_PRODUCT({ id: this.productDetail.id, ...filter })
            .then(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('common.success'),
                  styleType: ToastType.SUCCESS
                })
                this.onClose()
              }.bind(this)
            )
            .catch(
              function () {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styletype: ToastType.ERROR
                })
              }.bind(this)
            )
        }
      } else {
        this.ON_SHOW_TOAST({
          message: results,
          styletype: ToastType.ERROR
        })
      }
    },
    /**
     * Close
     */
    onClose: function () {
      window.history.back()
    },
    getQrUrl: function (qrCode) {
      let domain = stringUtils.DOMAIN_BASE_PATH_ADMIN
      let productQrPath = `/product-qr/${qrCode}`
      return functionUtils.removeDoubleSlashesFromUrl(domain + productQrPath)
    },
    //
    onSelectProductImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.getResultFile(files[0])
      this.productImgFileSize = files[0].size
      this.productDetail.productImg = URL.createObjectURL(files[0])
    },
    onClickRemoveProductImg: function () {
      let inputForm = $('#upload-product-img')
      inputForm.val('')
      this.productDetail.productImg = null
      this.productImgFileSize = null
    },
    getResultFile: function (file) {
      let vm = this
      vm.documentFile = file
    },
    uploadProductImg: async function () {
      if (this.productImgFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.cannotExceed10MB'),
          styleType: 'danger'
        })
      } else {
        let formData = new FormData()
        formData.append('file', this.documentFile)
        formData.append('directory', 'Stock/Product')
        formData.append('forceOverride', true)
        await axios({
          method: 'POST',
          timeout: 6000,
          url: this.media_server.domain + '/api/v1/upload/',
          data: formData,
          headers: { Authorization: `JWT ${this.media_server.token}` }
        })
          .then(
            function (res) {
              let productImgUrl = res.data.domain + res.data.url
              this.productDetail.productImg = productImgUrl
            }.bind(this)
          )
          .catch(
            function (error) {
              this.ON_SHOW_TOAST({
                message: error.response.data.message,
                styleType: 'danger'
              })
            }.bind(this)
          )
      }
    },
    ...mapActions([
      'GET_PRODUCT_DETAIL',
      'ON_SHOW_TOAST',
      'CREATE_PRODUCT',
      'UPDATE_PRODUCT',
      'GET_TOKEN_MEDIA_SERVER'
    ])
  }
}
</script>
<style scoped>
.product-img-class{
  /* width: 100%; */
  max-width: 200px;
  max-height: 200px;
  cursor: pointer;
}
input[type="file"] {
  display: none;
}
.remove-product-img{
  color: #EF5350;
  cursor: pointer;
}
.remove-product-img:hover{
  text-decoration: underline;
}
</style>
